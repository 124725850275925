import React, { useState, useRef, useEffect } from 'react';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import { ReactComponent as ThreeDots } from '../../../Assets/threeDots.svg';
import { ReactComponent as ChapterDelete } from '../../../Assets/chapterDelete.svg';
import { ReactComponent as ChapterRegenerate } from '../../../Assets/chapterRegenerate.svg';
import { ReactComponent as ChapterAdd } from '../../../Assets/chapterAdd.svg';

import { ReactComponent as ChapterDragAndDrop } from '../../../Assets/chapterDragAndDrop.svg';

import s from './ChapterItemEditor.module.scss';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import clsx from 'clsx';
import Spinner from 'Components/Common/Spinner/Spinner';
import { rxActiveChapterIndex } from 'rx/rxState';

interface IProps {
  chapter: IBookChapter;
  index: number;
  onChapterTitleEditedProps: (value: string, index: number) => void;
  activeChapter: number;
  setActiveChapter: (index: number) => void;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  isBookTitleActive: boolean;
  setIsBookTitleActive: (arg: boolean) => void;
  onChapterRegenerate: (index: number) => void;
  addingChapterIndex: null | number;
  blocked: boolean;
  dragableData: any;
}

function ChapterItemEditor({
  chapter,
  index,
  onChapterTitleEditedProps,
  activeChapter,
  setActiveChapter,
  deleteChapter,
  onChapterAdd,
  isBookTitleActive,
  setIsBookTitleActive,
  onChapterRegenerate,
  addingChapterIndex,
  blocked,
  dragableData
}: IProps) {
  const isGeneratedAllBookEnd = activeChapter !== null;
  const [chapterTitleInput, setChapterTitleInput] = useState<string>(
    chapter.title
  );
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState<boolean>(false);
  const [isDotsMenuHasBeenClick, setDotsMenuHasBeenClick] = useState<boolean>(false);
  const [hasCurrentChapterActive, setHasCurrentChapterActive] = useState(false);

  const refDotsMenu = useRef(null);
    
  useEffect(() => {
    setChapterTitleInput(chapter.title);
  }, [chapter]);

  const onChapterTitleEdited = (value: string) => {
    onChapterTitleEditedProps(value, index);
  };

  const onChapterClick = () => {
    if (isGeneratedAllBookEnd) {
      rxActiveChapterIndex.next(index + 1);
    }
  };

  useEffect(() => {
    setHasCurrentChapterActive(index === activeChapter);
  }, [index, activeChapter])

  const handleDotsMenuOpen = () =>{
    onChapterClick();
    setIsDotsMenuOpen(true);
  }

  useEffect(()=>{
    if (isDotsMenuHasBeenClick) {
      setTimeout(() => {
        setDotsMenuHasBeenClick(false)
        handleDotsMenuOpen()
      }, 100);
    }
  },[isDotsMenuHasBeenClick]);

  return (
    <div
      className={clsx(s.chapterItemWrapperEditVersion, {
        [s.chapterItemWrapperEditVersionActive]:
          index === activeChapter && !isBookTitleActive,
      })}
    >
      <div
        className={clsx(s.chapterItemEditVersion, {
          [s.chapterItemActiveEditVersion]:
            index === activeChapter && !isBookTitleActive,
        })}
        onClick={() => onChapterClick()}
      >
        <div
          className={clsx(
            s.chapterIndexCircle,
            s.chapterIndexCircleEditVersion,
            {
              [s.chapterIndexCircleNoneDisabled]:
                !isGeneratedAllBookEnd,
              [s.chapterIndexCircleGenerated]:
                !isGeneratedAllBookEnd,
              [s.chapterIndexCircleGeneratedEnd]: isGeneratedAllBookEnd,
            }
          )}
        >
          {addingChapterIndex === index ? (
            <Spinner size={25} />
          ) : (
            index + 1
          )}
        </div>
        <div className={s.chapterTitleInputEditVersion}>
          <ContentEditableDiv
            name={chapterTitleInput}
            fontSize={14}
            lineHeight={20}
            fontWeight={500}
            width={180}
            height={40}
            onClick={ () => onChapterClick()}
            onChange={
              !isGeneratedAllBookEnd ? () => {} : setChapterTitleInput
            }
            onEdit={!isGeneratedAllBookEnd ? () => {} : onChapterTitleEdited}            
            isMultiline={true}
            blocked={blocked}
          />
        </div>
        <div
              {...dragableData.dragHandleProps}
            >
        <ChapterDragAndDrop className={s.chapterDragAndDropSvgEditVersion} />
        </div>
        <ThreeDots
          className={s.threeDotsSvgEditVersion}
          ref={refDotsMenu}
          onClick={() => !blocked && handleDotsMenuOpen()}
        />
         <DropdownBox
          isOpen={isDotsMenuOpen && hasCurrentChapterActive}
          onClose={() => setIsDotsMenuOpen(false)}
          pointerEvents={true}
          componentRef={refDotsMenu}
          height={70}
        >
          <div className={s.dotsMenuBlock}>
            <div
              className={
                blocked
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                blocked
                  ? () => {console.log('blocked')}
                  : () => {
                      onChapterRegenerate(index);
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterRegenerate /> Regenerate
            </div>
            <div
              className={
                blocked
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                blocked
                  ? () => {}
                  : () => {
                      onChapterAdd(index, 'insert');
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterAdd /> Add New Above
            </div>
            <div
              className={
                blocked
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                blocked
                  ? () => {}
                  : () => {
                      onChapterAdd(index + 1, 'insert');
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterAdd /> Add New Below
            </div>
            <div
              className={
                blocked
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                blocked
                  ? () => {}
                  : () => {
                      deleteChapter(index);
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterDelete /> Delete
            </div>
          </div>
        </DropdownBox>
      </div>
    </div>
  );
}

export default ChapterItemEditor;
