import { useState, useEffect } from 'react';
import styled from 'styled-components';

import Tooltip from 'Components/Common/Tooltip/Tooltip';
import ColorSelector from '../ColorSelector';
import { ColorStyleMap } from './StyleMaps';
import { applyStyle, getStyleChar } from './StyleControlsUtils';

const ControlStyle = styled.div`
  position: relative;
  justify-content: space-between;
  height: initial;
  display: flex;
  align-items: center;
  margin-right: 10px;
  z-index: 1003;
`;

const ColorStyleControls = (props) => {
  const initialColor = '#000000';

  const [color, setColor] = useState(initialColor);
  const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);

  useEffect(() => {
    const newColor = getValue(props.editorState);

    if (newColor === 0) {
    } else {
      if (newColor === undefined) {
        setColor('#000000');
      } else {
        setColor(newColor);
      }
    }
  }, [props.editorState]);

  const onChange = (colorValue) => {
    const style = 'color-' + colorValue;
    const oldStyle = 'color-' + color;
    let nextEditorState = applyStyle(props.block, props.editorState, style, {
      ...ColorStyleMap,
      [oldStyle]: {},
    });
    setColor(colorValue);
    props.onChange(nextEditorState);
  };

  const getColorFromChar = (char) => {
    if (!char) {
      return initialColor;
    }
    const arr = char.getStyle().toArray();
    let charColor;
    arr.forEach((style) => {
      if (style.search('color-') === 0) {
        charColor = style.substring(6);
      }
    });
    return charColor;
  };

  const getValue = (editorState) => {
    let char = getStyleChar(editorState);
    return getColorFromChar(char);
  };

  return (
    <Tooltip
      text="Text color"
      visible={isColorSelectorOpen ? false : undefined}
    >
      <ControlStyle>
        <ColorSelector
          color={color}
          onChange={onChange}
          onSelectorToggle={(isOpen) => setIsColorSelectorOpen(isOpen)}
        />
      </ControlStyle>
    </Tooltip>
  );
};

export default ColorStyleControls;
